import './styles.css';

import { SeoHeader } from '@components/Common';
import { Footer, NavigationHeader } from '@components/Navigation';
import {
  getHtmlHeadersTree,
  PostContent,
  PostHeaderElement,
  PostSidebar,
  PostTableOfContentsMobileDropdown,
} from '@components/Post';
import { SystemDocument } from '@core/content';
import { mc } from '@styles';
import { stripHtml, textToId } from '@utils';
import { graphql } from 'gatsby';
import React, { FC, useEffect, useState } from 'react';

interface PageContext {
  slug: string;
}

interface Props {
  data: { system: SystemDocument };
  pageContext: PageContext;
}

const SystemPage: FC<Props> = ({ data }) => {
  const { system } = data;

  const [visible, setVisible] = useState<Element>();
  const [body, setBody] = useState<string>(system.itemContent.content.mainContent);
  const [contents, setContents] = useState<PostHeaderElement[]>([]);

  useEffect(() => {
    const regex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;
    setBody(system.itemContent.content.mainContent.replace(regex, '<a href="mailto:$1">$1</a>'));
  }, [system.itemContent.content.mainContent]);

  useEffect(() => {
    setContents(getHtmlHeadersTree(system.itemContent.content.mainContent));
  }, [system.id]);

  const handleVisibilityChange = (element: Element, inView: boolean) => {
    if (inView) setVisible(element);
  };

  const handleTableContentsItemClick = (itm: PostHeaderElement) => {
    const el = document.getElementById(itm.id);
    if (!el) return;
    setVisible(el);
  };

  return (
    <>
      <NavigationHeader />

      <section className="bg-light-gray flex flex-col justify-center" style={{ minHeight: 360 }}>
        <div className={mc('px-4 sm:px-8 lg:px-16', 'container mx-auto')}>
          <h1 className={mc('text-4xl md:text-5xl text-center lg:text-left')}>
            {stripHtml(system?.itemContent?.content?.titleOfSystemPage)}
          </h1>
        </div>
      </section>

      <div className="lg:hidden mt-10">
        <h6 className="block mb-4 font-bold text-lg leading-6 px-8 font-raleway mt-0">Table of Contents</h6>

        <PostTableOfContentsMobileDropdown
          contents={contents}
          active={visible ? textToId(visible.textContent) : undefined}
          onItemClick={handleTableContentsItemClick}
        />
      </div>

      <div className="container mx-auto px-8 xl:px-20 overflow-hidden">
        <div className="flex flex-col lg:flex-row justify-between pb-16 lg:-mx-5 xl:-mx-10">
          <div className="w-full lg:w-8/12 lg:px-5 xl:px-10 system-page">
            <section className="mt-10 xl:mt-20 container mx-auto xl:pr-4">
              <PostContent className="text-dark-gray" html={body} onHeaderElementVisibilityChange={handleVisibilityChange} />
            </section>
          </div>

          <div className="hidden lg:block w-full mt-10 xl:mt-20 lg:w-4/12 lg:px-5 xl:px-10">
            <PostSidebar
              hasSearch={false}
              hasDownloadBar={false}
              active={visible ? textToId(visible.textContent) : undefined}
              contents={contents}
              onTableContentsItemClick={handleTableContentsItemClick}
            />
          </div>
        </div>
      </div>

      <Footer privacyBannerColor="seafoam" />
    </>
  );
};

export const query = graphql`
  query ($slug: String!) {
    system: gathercontentItems(slug: { eq: $slug }) {
      id
      slug
      fields {
        createdAt
        updatedAt
      }

      itemContent {
        content {
          titleOfSystemPage
          mainContent
        }
      }
    }
  }
`;

export const Head: FC<Props> = ({ data }) => <SeoHeader title={stripHtml(data.system.itemContent.content.titleOfSystemPage)} />;

export default SystemPage;
